import * as React from "react";
import { DataGrid } from "@mui/x-data-grid";
import axios from "axios";

const columns = [
  {
    field: "id",
    headerName: "S.No.",
    width: 70,
    editable: true,
  },
  {
    field: "ts",
    headerName: "Time",
    width: 290,
    editable: true,
    renderCell: ((params)=> {
      return (
         <div>
             {new Date(new Date(params.row.ts).getTime()).toLocaleString()}
         </div>
      )
   }

   )
  },
  {
    field: "imei",
    headerName: "IMEI",
    width: 290,
    editable: true,
  },
  {
    field: "log_data",
    headerName: "Data Received",
    width: 710,
    editable: true,
  },
];
const secondColumn = [
  {
    field: "id",
    headerName: "S.No.",
    width: 70,
    editable: true,
  },
  {
    field: "ts",
    headerName: "Time",
    width: 290,
    editable: true,
    renderCell: ((params)=> {
       return (
          <div>
              {new Date(new Date(params.row.ts).getTime()).toLocaleString()}
          </div>
       )
    }

    )
  },
  {
    field: "imei",
    headerName: "IMEI",
    width: 290,
    editable: true,
  },
  {
    field: "pressed",
    headerName: "Pressed ?",
    width: 710,
    editable: true,
  },
];
const url = process.env.REACT_APP_URL

function TelemetryLogs() {
  const [telemetryData, setTelemetryData] = React.useState([]);
  const [buttonPressedData, setButtonPressedData] = React.useState([]);
  React.useEffect(() => {
    axios
      .get(`${url}/api/v1/telemetry_logs`)
      .then((response) => {
        console.log(response.data.data);
        const received_data = response.data.data;
        const updatedTimeArray = received_data.map((item, index) => {
          var date1 = new Date(item.ts.slice(0, 19));
          var ws = date1.toString();
          var ds = ws.slice(0, 24);
          return {
            id: index + 1,
            ts: ds,
            imei: item.imei,
            log_data: item.log_data,
          };
        });
        console.log("uidhc", updatedTimeArray);
        setTelemetryData(updatedTimeArray);
      });
    axios
      .get(`${url}/api/v1/button_pressed`)
      .then((response) => {
        const updatedTimeArray = response.data.data.map((item, index) => {
          var date1 = new Date(item.ts.slice(0, 19));
          var ws = date1.toString();
          var ds = ws.slice(0, 24);
          return {
            id: index + 1,
            ts: ds,
            imei: item.imei,
            pressed: item.pressed,
          };
        });
        setButtonPressedData(updatedTimeArray);
      });
  }, []);
  return (
    <div
      style={{
        height: 500,
        width: "100%",
        marginTop: "10px",
        padding: "1% 2%",
      }}
    >
      <h3>All Telemetry Log Data:-</h3>
      <DataGrid
        align="center"
        rows={telemetryData}
        columns={columns}
        pageSize={100}
        rowsPerPageOptions={[15]}
        // checkboxSelection
        disableSelectionOnClick
        getRowId={(row) => row.id}
      />
      <br />
      <h3>Button Pressed Data :-</h3>
      <DataGrid
        align="center"
        rows={buttonPressedData}
        columns={secondColumn}
        pageSize={100}
        rowsPerPageOptions={[15]}
        // checkboxSelection
        disableSelectionOnClick
        getRowId={(row) => row.id}
      />
    </div>
  );
}

export default TelemetryLogs;
