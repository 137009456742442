import React from "react";
import { Helmet } from "react-helmet";
import IndividualDevice from "../../Components/Device/IndividualDevice";
import PrimarySearchAppBar from "../../Components/Layout/Header";
import Footer from "../../Components/Home/Footer";
import CssBaseline from "@mui/material/CssBaseline";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import { Grid } from "@mui/material";
import DeviceDetailsCard from "../../Components/Device/DeviceDetailsCard";
import DeviceMap from "../../Components/Device/DeviceMap";
import Example from "../../Components/Device/CallReportChart";
import GraphSections from "../../Components/Device/GraphSections";
import Recording from "../../Components/Device/Recording";

function index() {
  return (
    <React.Fragment>
      <Helmet titleTemplate="PRSS | %s" title="Device" />
      <PrimarySearchAppBar />
      <CssBaseline />
      <div id="bgcolor">  

     
      <Container className="hu" maxWidth="lg">
        <Grid sx={{ marginTop: "0.6rem" }} container>
          <Grid container item direction={"row"} md={12} xs={12}>
            
              <DeviceDetailsCard />
           

           
          </Grid>
          <Grid container item direction={"row"} md={12} xs={12}>
            
              <GraphSections />
           

           
          </Grid>
          {/* <Grid container item direction={"row"} md={12} xs={12}>
            
              <Recording />
           

           
          </Grid> */}
          
        </Grid>
      </Container>
      </div>
    </React.Fragment>
  );
}

export default index;
